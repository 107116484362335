<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="70"
  >
    <v-btn
      class="mr-3"
      min-width="0"
      text
      small
      @click="toggleDrawer"
    >
      <v-icon v-if="drawer">
        mdi-chevron-double-right
      </v-icon>
      <v-icon v-else>
        mdi-chevron-double-left
      </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light dnone">
      <span
        v-if="$route.meta.parent_name && $route.meta.parent_name.length > 0"
      >
        <span
          v-for="(pName, i) in $route.meta.parent_name"
          :key="i"
        >
          {{ $t(pName) }}
          <v-icon>mdi-chevron-right</v-icon>
        </span>
      </span>
      <span>
        {{ $t($route.name) }}
      </span>
    </v-toolbar-title>

    <!-- <div class="locale-changer mx-10">
      <v-select
        style="width: 70px"
        hide-details
        v-model="currentLang"
        :items="langs"
      ></v-select>
    </div> -->
    <v-spacer />

    <!-- <v-text-field :label="$t('search')" color="secondary" hide-details style="max-width: 165px;">
        <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
            <v-btn class="mt-n2" elevation="1" fab small>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </template>
    </v-text-field> -->

    <!-- <div class="mx-3" /> -->
    <!-- <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-bell</v-icon>
    </v-btn> -->

    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-store</v-icon>
          <span class="px-2"> {{ 'Orko' }}</span>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div
          v-for="store in stores"
          :key="store.id"
          @click="updateStore(store)"
        >
          <app-bar-item>
            <v-list-item-title v-text="store.name" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <div
      class="ml-2"
      min-width="0"
      text
    >
      <v-icon>mdi-account</v-icon>
      <span class="px-2">{{ user.email }}</span>
    </div>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="logout"
    >
      <v-icon>mdi-power-standby</v-icon>
    </v-btn>
    <v-btn
      min-width="0"
      text
      @click="setNotifyDrawer(!notifyDrawer)"
    >
      <v-badge
        v-if="listLen"
        overlap
        color="primary"
        :content="listLen"
      >
        <v-icon>mdi-bell</v-icon>
      </v-badge>
      <v-icon v-else>
        mdi-bell
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
/* eslint-disable */
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "DashboardCoreAppBar",
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    currentLang: "fr",
    langs: [
      { text: "FR", value: "fr" },
      { text: "EN", value: "en" },
    ],
  }),

  mounted() {
    this.currentLang = localStorage.currentLang
      ? localStorage.currentLang
      : this.$i18n.locale;
  },

  watch: {
    currentLang(newLang) {
      localStorage.currentLang = newLang;
      this.$i18n.locale = newLang;
    },
  },

  computed: {
    ...mapGetters({
      list: "alertsAndNotify/getNotifyList",
    }),
    listLen() {
      return this.list.filter((r) => r?.isRead == false)?.length;
    },
    ...mapState(["drawer", "notifyDrawer"]),
    user() {
      return this.$store.getters.getUser;
    },
    stores() {
      return [];
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      setNotifyDrawer: "SET_NOTIFY_DRAWER",
    }),
    toggleDrawer() {
      this.setDrawer(!this.drawer);

      // Toggle class on body
      const body = document.body;
      if (this.drawer) {
        body.classList.remove("drawerOpen");
      } else {
        body.classList.add("drawerOpen");
      }
    },
    async updateStore(store) {
      try {
        await this.$store.dispatch("updateMyStore", {
          next_store_id: store.id,
        });
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    logout() {
      this.$store.dispatch("logout");
      this.$store.dispatch("checkAuth");
    },
  },
};
</script>
<style lang="sass" scoped>
#app-bar
  // position: sticky
  // top: 0
  :deep .v-toolbar__content
    background: #FFF
</style>
